var translation = function () {

    var translation = this;

    this.bindEvents = function () {
        $('[data-interaction=upload]').each(function () {
            var locale = $(this).attr('data-locale');

            $(this).dropzone({
                url: app.baseUrl + "/translation/" + locale + "/upload",
                headers: $.ajaxSetup().headers,
                previewTemplate: '<div style="display: none;"></div>',
                sending: function(file, data){
                    app.block(1);
                },
                success: function (file, data) {
                    app.block(0);
                    $('.dz-preview').detach();

                    app.reload();
                },
                error: function (file, data) {
                    app.block(0);
                    app.error(T.serverError);
                }
            });
        });

        $('[data-interaction=update]').unbind('click').bind('click', function () {
            app.block(1);
            $.post(app.baseUrl + "/translation/update", {})
                .done(function (data) {
                    if (data.response) {
                        app.reload();
                    } else {
                        app.warning("", data.message);
                    }

                    app.block(0);
                })
                .fail(function (data) {
                    app.block(0);
                    app.error("", app.parseAjaxError(data));
                });
        });

        $('[data-interaction=download]').unbind('click').bind('click', function () {
            var locale = $(this).attr("data-locale");

            app.block(1);
            $.post(app.baseUrl + "/translation/update", {})
                .done(function (data) {
                    if (data.response) {
                        app.reload();
                        window.location.href = app.baseUrl + "/translation/" + locale + "/download?" + Math.random();
                    } else {
                        app.warning("", data.message);
                    }

                    app.block(0);
                })
                .fail(function (data) {
                    app.block(0);
                    app.error("", app.parseAjaxError(data));
                });
        });
    };

};